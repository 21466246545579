
class MenuTextImage {

    constructor() {
        
    }

    init() {
    
          // event info accordion
        //   $('.event-infos .content').hide()
          //----- click function
          $('.text-image-menu').find('.menu-item-has-children a').click(function(e) { //---- tabs or buttons
              //---- active class
               
              e.preventDefault();
              $(this).parent('.menu-item').removeClass('active')
              $('.menu-item').removeClass('active')
              $('.text-image-menu ul.sub-menu').slideUp('fast');
              var selected = $(this).next('.text-image-menu ul.sub-menu');
              if (selected.is(':hidden')) {
                  $(this).next('.text-image-menu ul.sub-menu').slideDown('fast');
                  $(this).parent('.menu-item').addClass('active')
              }
          });
    }
  
}

export default (new MenuTextImage);
