/* Utils */
import { breakpoint } from '../utils/variables';
import Tools from '../utils/tools';

/* Components */
import Accordion from '../components/accordion';
import MegaMenu from '../components/header-megamenu';
import MenuTextImage from '../components/menu-text-image'
import Burger from '../components/header-burger';
import Animations from '../components/animations';

import Lang from '../components/lang';

/* Layouts */
import Header from '../layouts/header';
import PageLoader from '../layouts/page-loader';
import { Modal } from '../layouts/modal';
// import { Gform } from './layouts/gform'; // À décommenter si gravity-form est installé !!

/* Modules */
import Video from '../modules/video';
import KeyFigures from '../modules/key-figures';
import CommonSlideshow from '../modules/common-slideshow';

const modal = {
    default: new Modal(),
    test: new Modal('#modal-trigger-test', '#modal-target-test'), // permet d'afficher la modal test. Sert d'exemple, à supprimer lors de l'inté
};

var isResponsiveOnLoad = window.innerWidth >= breakpoint.desktop ? false : true;

export default {

    init() {

        // JavaScript to be fired on all pages

        Tools.objectFitImages();
        Tools.smoothScroll();
        // Tools.detectIe11(); // (optionel)
        // Tools.initEqualHeight(); // (optionel)

        /* Components */
        Burger.init();
        MenuTextImage.init();
        
        /* Layouts */
        modal.default.init();
        // permet d'inialiser une modal via des selectors différents
        modal.test.init();

        /* À décommenter si gravity-form est installé !! */
        // Gform.add_default_classes();
        // Gform.close_error_notice();

        Accordion.init();

        /* Modules*/
        Video.reveal();
        KeyFigures.init();
        CommonSlideshow.init();
        Animations.init();

        Lang.init();
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
    load() {
        MegaMenu.init();
        Header.set_sticky_class();
        PageLoader.init();
    },
    complete() {
        // S'exécute en callback du fadeOut() du PageLoader.init() on load

        MegaMenu.hide_all_submenu();

        /* Modules */
        KeyFigures.lauch();
        function hasTouch() {
            return 'ontouchstart' in document.documentElement
                   || navigator.maxTouchPoints > 0
                   || navigator.msMaxTouchPoints > 0;
          }
          
          if (hasTouch()) { // remove all the :hover stylesheets
            try { // prevent exception on browsers not supporting DOM styleSheets properly
              for (var si in document.styleSheets) {
                var styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;
          
                for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                  if (!styleSheet.rules[ri].selectorText) continue;
          
                  if (styleSheet.rules[ri].selectorText.match(':hover')) {
                    styleSheet.deleteRule(ri);
                  }
                }
              }
            } catch (ex) {
                console.log(ex)
            }
          }
    },
    resize() {
        // Header.stickyBodyClassRemove();
    },
    resizeEnd() {
        // event qui se déclenche après 100ms une fois qu'on arrête de resize le navigateur

        MegaMenu.add_data_submenu_height();

        if (window.innerWidth >= breakpoint.desktop) {
            MegaMenu.reset();
            isResponsiveOnLoad = false;
        }
        else {
            if (!isResponsiveOnLoad) {
                MegaMenu.submenu_mobile_reset();
                MegaMenu.hide_all_submenu();
            }
            isResponsiveOnLoad = true;
        }
    },
    resizeStart() {
        MegaMenu.display_all_submenu();
    },
    scroll() {
        Header.set_sticky_class();
    },
    scrollEnd() {
        // event qui se déclenche après 100ms une fois qu'on arrête de scroller
    },
};